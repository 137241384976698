var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"app-wrapper wrapper-view"},[_c('div',{staticClass:"wrapper-title"},[_vm._v("素材审核")]),_c('div',{staticClass:"content-head"},[_c('div',{staticClass:"head-btn"},[_c('div',{staticClass:"btn",on:{"click":_vm.handleAudited}},[_vm._v("审核通过")])])]),_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.isLoading),expression:"isLoading"}],staticClass:"source-material-list"},_vm._l((_vm.tableData),function(item){return _c('div',{key:item.id,staticClass:"source-material-item"},[_c('div',{staticClass:"item-content"},[_c('img',{attrs:{"src":item.material_detail.video_cover_path,"alt":""}}),_c('div',{staticClass:"content-img",on:{"click":() => {
              _vm.video = {
                show: true,
                data: {
                  content: item.material_detail.filepath,
                },
              };
            }}},[_c('img',{attrs:{"src":require("../../../../../assets/icon/play.png"),"alt":"","srcset":""}})])]),_c('div',{staticClass:"item-remove"},[_c('span',{on:{"click":function($event){return _vm.handleRemove(item)}}},[_c('i',{staticClass:"el-icon-delete"}),_vm._v(" 删除")])])])}),0),(_vm.video.show)?_c('VideoView',_vm._b({attrs:{"show":_vm.video.show},on:{"update:show":function($event){return _vm.$set(_vm.video, "show", $event)}}},'VideoView',_vm.video,false)):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }