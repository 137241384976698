<!--
 * @Description: 视频
 * 
 * @Author: XiongYaoYuan<xiongyaoyuan>
 * @Date: 2023-09-20 22:05:58
 * @FilePath: /JinChanTuoKe-Front/src/pages/views/custom/examine/mods/videos.vue
-->

<template>
  <div class="app-wrapper wrapper-view">
    <div class="wrapper-title">素材审核</div>
    <div class="content-head">
      <div class="head-btn">
        <div class="btn" @click="handleAudited">审核通过</div>
      </div>
    </div>
    <div class="source-material-list" v-loading="isLoading">
      <div
        class="source-material-item"
        v-for="item in tableData"
        :key="item.id"
      >
        <div class="item-content">
          <img :src="item.material_detail.video_cover_path" alt="" />
          <div
            class="content-img"
            @click="
              () => {
                video = {
                  show: true,
                  data: {
                    content: item.material_detail.filepath,
                  },
                };
              }
            "
          >
            <img src="../../../../../assets/icon/play.png" alt="" srcset="" />
          </div>
        </div>
        <div class="item-remove">
          <span @click="handleRemove(item)">
            <i class="el-icon-delete"></i> 删除</span
          >
        </div>
      </div>
    </div>

    <!-- 播放视频 -->
    <VideoView v-if="video.show" :show.sync="video.show" v-bind="video" />
  </div>
</template>

<script>
import { HTTP } from "../../../../../utils/request";
import VideoView from "../components/video.vue";

const URL = {
  taskDetail: "api/operation/material/taskDetail",
  remove: "api/operation/material/remove",
  audited: "api/operation/material/audited",
};

export default {
  components: {
    VideoView,
  },
  data() {
    return {
      id: this.$route.query?.id,
      tableData: [],
      video: {
        show: false,
        data: null,
      },
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    async getList() {
      this.isLoading = true;
      const res = await HTTP({
        url: URL.taskDetail,
        method: "get",
        data: {
          id: this.id,
        },
      });
      this.tableData = res.log || [];
      this.isLoading = false;
    },
    handleRemove({ id }) {
      this.$confirm("确认删除此数据吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          HTTP({
            url: URL.remove,
            method: "post",
            data: {
              auditId: this.id,
              id,
            },
          }).then(() => {
            this.$message.success("删除成功");
            this.getList();
          });
        })
        .catch(() => {
          this.$message.info("已取消【删除】操作");
        });
    },
    handleAudited() {
      this.$confirm("确认通过？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          HTTP({
            url: URL.audited,
            method: "post",
            data: {
              id: this.id,
            },
          }).then(() => {
            this.$message.success("审核成功");
            this.getList();
          });
        })
        .catch(() => {
          this.$message.info("已取消【审核】操作");
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.tip {
  display: flex;
  font-size: 12px;
  font-family: SourceHanSansCN-Regular-, SourceHanSansCN-Regular;
  font-weight: normal;
  color: #7c7f97;
  line-height: 24px;
  margin-top: 10px;
}
.source-material-list {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  border-left: 1px solid #e4e8fc;
  margin-top: 14px;
  .source-material-item {
    width: calc(100% / 6);
    padding: 20px;
    border-right: 1px solid #e4e8fc;
    border-bottom: 1px solid #e4e8fc;
    border-top: 1px solid #e4e8fc;
    .item-content {
      width: 126px;
      height: 222px;
      background-color: #eee;
      margin: 0 auto;
      position: relative;
      & > img {
        width: 100%;
        height: 100%;
      }
      .content-img {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background-color: rgba($color: #000000, $alpha: 0.1);
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          width: 30px;
          height: 30px;
          cursor: pointer;
        }
      }
    }
    .item-remove {
      text-align: center;
      font-size: 16px;
      font-family: SourceHanSansCN-Regular-, SourceHanSansCN-Regular;
      font-weight: normal;
      color: #7c7f97;
      margin-top: 20px;
      &:hover {
        color: #4e6bef;
        cursor: pointer;
      }
    }
    &:hover {
      background-color: #f6f6fd;
    }
  }
}
.content-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  .head-btn {
    display: flex;
    align-items: center;
    .btn {
      width: 128px;
      height: 33px;
      background: #4e6bef;
      margin-right: 12px;
      font-size: 16px;
      font-family: SourceHanSansCN-Regular-, SourceHanSansCN-Regular;
      font-weight: normal;
      color: #ffffff;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 4px;
      cursor: pointer;
    }
  }
}
</style>
